<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 2908 2116"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <path
      d="M2473.47,273.25c-309.5,-178.792 -662.125,-273.25 -1019.75,-273.25c-357.546,0 -710.083,94.458 -1019.58,273.25l-17.209,9.917l0,56.833c0,58.625 2.538,117.083 7.625,175.292c21.996,-4.375 44.834,-8.875 68.209,-13.459c-4.459,-53.541 -6.709,-107.625 -6.709,-161.833l0,-16.833c294.75,-166.25 628.959,-254.084 967.667,-254.084c338.875,0 673.083,87.834 967.829,254.084l0,16.833c0,111.667 -9.583,222.875 -28.287,332.375c19.5,-0.667 39.125,-1 58.787,-1c3.796,0 7.588,0 11.421,0.083c18.042,-109.25 27.208,-220.166 27.208,-331.458l0,-56.833l-17.208,-9.917Zm-373.504,1227.79c-19.538,-0 -37.5,-1.375 -53.834,-4.25c-157.995,217.833 -359.745,401.833 -592.412,539.333c-236.213,-139.625 -436.921,-325.333 -593.208,-541.833c-0.875,-0.167 -1.75,-0.334 -2.584,-0.5c-5.958,1.291 -12.296,2.041 -18.671,2.041l-63.041,0c171.5,249.334 397.337,458.413 660.208,610.167l17.296,10l17.287,-10c261.75,-151.042 486.625,-358.75 657.792,-606.333c-9.333,0.916 -18.917,1.375 -28.833,1.375"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M400.766,678.611c16.358,-7.196 32.746,-14.363 49.196,-21.354c16.383,-7.084 32.916,-13.879 49.391,-20.788l49.621,-20.241c33.204,-13.25 66.442,-26.355 99.984,-38.717c8.425,-3.054 16.791,-6.196 25.27,-9.079c4.2,-1.484 8.48,-2.913 12.763,-4.284c4.196,-1.483 8.279,-2.741 12.133,-4.766c7.709,-3.771 14.846,-9.167 19.671,-16.475c1.8,-2.513 3.229,-5.225 4.429,-8.079c-181.5,51.591 -323.146,121.395 -356.521,138.47c2.659,2.659 5.767,4.796 9.28,6.142c7.995,3.313 17.162,2.683 24.783,-0.829"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M2766.18,951.332c-58.333,-5.25 -127.962,-36.083 -159.087,-72.167c-0,0 44.046,-40.625 209.962,27.042c0,0 7.459,50.375 -50.875,45.125m-1433,-227.667l0,-202c403.292,-3.083 538.5,202.084 538.5,202.084l-538.5,-0.084Zm-32.079,0.084l-615.208,-3.667l-138.296,3.667c221.254,-231.542 753.504,-202 753.504,-202l-0,202Zm-1051.5,94.666l-193.379,50.084c-0,-0 -5.334,-54.167 11.962,-75.042l155.954,3.792l25.463,21.166Zm2625.46,51.375c-183.087,-97.25 -753.212,-122.041 -753.212,-122.041c-0,-0 -194.042,-139.792 -390.792,-215.75c-196.746,-75.959 -631.996,-55.292 -631.996,-55.292c-128.546,0 -261.421,25.792 -375.833,58.125c-0.375,0.083 -0.708,0.208 -1.083,0.292c-1.042,3.416 -3.921,5.625 -5.75,8.75c-4.5,8 -13.463,8.291 -21.375,12.916c-3.959,2.459 -8.38,4 -12.546,5.75c-4.121,1.584 -8.246,3.167 -12.413,4.875l-24.754,10.042l-50.621,16.875l-49.625,20.125l-49.791,19.833c-16.667,6.459 -34.88,15.167 -51.584,21.542c-16.666,6.417 -24.666,13.375 -41.421,19.667c-8.787,3.291 -14.829,6.958 -23.204,2.791c-2.208,-1.083 -5.75,-1.333 -8.754,-1.958c-2.037,-0.458 -3.871,-1.125 -4.912,-2.375c-4.042,2.125 -6.171,3.208 -6.171,3.208c-68.663,4.625 -273.746,40.75 -273.746,40.75l-24.417,62.584c-11.166,20.833 -13.708,80.375 -13.708,80.375l-46.583,95.666l-0,60.042c-3.584,32.042 6.625,37.667 6.625,37.667c-6.546,-24.959 9.5,-38.167 9.5,-38.167l3.037,-60.708l40.046,-71.542l236.667,-49.917c-28.963,-38.166 -65.625,-59.041 -65.625,-59.041c-0,-0 531.208,-10.709 1168.29,-10.709c894.792,0 1198.13,87.375 1198.13,87.375c-24.791,10.834 -52.916,29.875 -52.916,29.875c113.287,120.084 311.579,96 311.579,96c7.837,40.542 51.375,76.834 51.375,76.834c2.416,-134 -29.621,-180.709 -32.413,-184.459"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M791.619,801.981l108.383,0c12.221,0 22.129,9.909 22.129,22.134l-109.841,-0c-11.417,-0 -20.671,-9.255 -20.671,-20.671l-0,-1.463Z"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M1452.21,813.049l108.384,-0c12.221,-0 22.129,9.908 22.129,22.133l-109.842,0c-11.416,0 -20.671,-9.254 -20.671,-20.671l0,-1.462Z"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M344.817,1030.81c12.163,-49.638 42.942,-95.271 84.779,-126.95c41.625,-32.096 94.542,-49.775 147.496,-49.717c52.963,-0.091 105.888,17.596 147.517,49.692c41.846,31.675 72.625,77.329 84.762,126.975c-20.416,-46.717 -53.366,-86.383 -94.233,-113.767c-40.867,-27.371 -89.321,-42.091 -138.042,-42.071c-48.729,-0.045 -97.191,14.684 -138.062,42.046c-40.879,27.379 -73.825,67.067 -94.217,113.792"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M2171.73,1035.67c11.305,-47.091 40.396,-90.466 80.046,-120.583c39.446,-30.525 89.679,-47.346 139.95,-47.292c50.267,-0.087 100.517,16.742 139.967,47.267c39.658,30.113 68.75,73.508 80.025,120.608c-19.558,-44.166 -50.821,-81.583 -89.504,-107.4c-38.679,-25.8 -84.45,-39.662 -130.488,-39.65c-46.037,-0.041 -91.821,13.83 -130.508,39.625c-38.696,25.817 -69.958,63.25 -89.488,107.425"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M247.344,1279.29c-4.079,-8.501 -9.875,-15.792 -17.334,-21.921c-7.541,-6.084 -16.666,-11.163 -27.458,-15.246c-10.833,-4.083 -22.829,-7.879 -36.042,-11.458c-10.208,-2.542 -19.166,-4.959 -26.916,-7.209c-7.75,-2.292 -14.167,-4.837 -19.246,-7.625c-5.088,-2.833 -8.963,-6.046 -11.629,-9.75c-2.667,-3.666 -3.996,-8.333 -3.996,-13.916c-0,-9.667 3.537,-17.167 10.662,-22.505c7.125,-5.329 17.917,-7.995 32.417,-7.995c8.125,-0 16.125,1 24.042,3.041c7.879,2.038 15.171,4.5 21.916,7.413c6.75,2.958 12.459,5.921 17.167,9c4.708,3.045 7.958,5.462 9.708,7.254l23.292,-42.75c-11.958,-8.125 -25.958,-15.171 -41.958,-21.167c-16.042,-5.962 -33.584,-8.962 -52.625,-8.962c-13.75,-0 -26.584,1.879 -38.542,5.546c-11.958,3.662 -22.417,9.083 -31.458,16.204c-9.042,7.129 -16.084,16.004 -21.167,26.708c-5.083,10.671 -7.621,22.879 -7.621,36.588c0,10.454 1.579,19.416 4.788,26.916c3.166,7.496 7.916,14.079 14.291,19.829c6.334,5.709 14.292,10.671 23.834,14.88c9.541,4.166 20.791,8.037 33.75,11.625c10.666,3.041 20.333,5.833 28.958,8.37c8.667,2.546 16.042,5.338 22.125,8.38c6.125,3.079 10.833,6.625 14.125,10.708c3.292,4.037 4.958,9 4.958,14.875c0,18.542 -14.5,27.833 -43.458,27.833c-10.458,0 -20.625,-1.292 -30.542,-3.833c-9.916,-2.546 -18.916,-5.625 -27.083,-9.338c-8.125,-3.666 -15.167,-7.37 -21.167,-11.037c-5.958,-3.713 -10.083,-6.708 -12.375,-8.958l-23.25,45.375c15.75,11.166 33.542,19.833 53.375,25.916c19.834,6.125 39.917,9.167 60.25,9.167c13.209,-0 25.875,-1.458 37.959,-4.379c12.083,-2.959 22.75,-7.579 32.041,-13.954c9.25,-6.334 16.625,-14.667 22.125,-24.963c5.459,-10.287 8.167,-22.708 8.167,-37.162c0,-12.463 -2.042,-22.959 -6.083,-31.5"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M505.718,1285.79c-3.413,10.792 -8.542,20.542 -15.25,29.167c-6.746,8.667 -15,15.583 -24.788,20.792c-9.795,5.208 -21.045,7.833 -33.754,7.833c-12.458,0 -23.583,-2.542 -33.375,-7.625c-9.787,-5.083 -18.041,-11.833 -24.791,-20.208c-6.75,-8.417 -11.875,-18.079 -15.459,-29c-3.537,-10.917 -5.333,-22.246 -5.333,-33.959c-0,-11.166 1.708,-22.166 5.167,-32.958c3.416,-10.833 8.458,-20.5 15.041,-29c6.625,-8.5 14.879,-15.371 24.796,-20.579c9.917,-5.208 21.246,-7.833 33.954,-7.833c12.213,-0 23.209,2.495 33,7.454c9.75,4.958 18.042,11.625 24.792,20c6.712,8.375 11.921,18.046 15.625,29c3.667,10.921 5.542,22.25 5.542,33.916c-0,11.213 -1.75,22.209 -5.167,33m48.625,-82.583c-6.371,-16.5 -15.246,-31.25 -26.704,-44.208c-11.417,-13 -25.213,-23.459 -41.379,-31.454c-16.125,-8.046 -34,-12.046 -53.58,-12.046c-19.045,-0 -36.754,3.75 -53.004,11.25c-16.291,7.5 -30.329,17.629 -42.121,30.333c-11.837,12.708 -21.129,27.333 -27.879,43.879c-6.708,16.5 -10.083,33.788 -10.083,51.829c-0,17.292 3.167,34.209 9.542,50.75c6.333,16.5 15.291,31.209 26.879,44.042c11.579,12.833 25.412,23.125 41.579,30.875c16.125,7.754 34.125,11.625 53.958,11.625c19.046,0 36.713,-3.75 53,-11.25c16.292,-7.5 30.254,-17.542 41.959,-30.121c11.666,-12.587 20.833,-27.083 27.458,-43.462c6.583,-16.417 9.917,-33.621 9.917,-51.663c-0,-17.046 -3.167,-33.837 -9.542,-50.379"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M679.803,1342.04l-0,-224.625l-52.625,0l-0,270.792l190.708,0l-0,-46.167l-138.083,0Z"
      style="fill: #fff; fill-rule: nonzero"
    />
    <rect
      x="872.803"
      y="1117.42"
      width="52.625"
      height="270.792"
      style="fill: #fff"
    />
    <path
      d="M1222.14,1117.41l-80.875,154.083l-80.083,-154.083l-56.417,0l0,270.792l52.625,-0l0,-179.246l69.792,133.454l28.583,0l69.792,-133.454l-0,179.246l52.625,-0l-0,-270.792l-56.042,0Z"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M1547.22,1285.79c-3.413,10.792 -8.5,20.542 -15.25,29.167c-6.708,8.667 -15,15.583 -24.788,20.792c-9.795,5.208 -21.045,7.833 -33.754,7.833c-12.454,0 -23.583,-2.542 -33.375,-7.625c-9.75,-5.083 -18.041,-11.833 -24.75,-20.208c-6.746,-8.417 -11.916,-18.079 -15.458,-29c-3.579,-10.917 -5.329,-22.246 -5.329,-33.959c-0,-11.166 1.708,-22.166 5.121,-32.958c3.458,-10.833 8.458,-20.5 15.083,-29c6.583,-8.5 14.879,-15.371 24.792,-20.579c9.916,-5.208 21.208,-7.833 33.916,-7.833c12.213,-0 23.209,2.495 33,7.454c9.796,4.958 18.042,11.625 24.792,20c6.75,8.375 11.958,18.046 15.625,29c3.712,10.921 5.542,22.25 5.542,33.916c-0,11.213 -1.709,22.209 -5.167,33m48.625,-82.583c-6.333,-16.5 -15.246,-31.25 -26.667,-44.208c-11.458,-13 -25.25,-23.459 -41.375,-31.454c-16.166,-8.046 -33.996,-12.046 -53.583,-12.046c-19.083,-0 -36.75,3.75 -53.042,11.25c-16.246,7.5 -30.291,17.629 -42.121,30.333c-11.833,12.708 -21.087,27.333 -27.837,43.879c-6.75,16.5 -10.125,33.788 -10.125,51.829c-0,17.292 3.212,34.209 9.542,50.75c6.379,16.5 15.333,31.209 26.879,44.042c11.583,12.833 25.454,23.125 41.579,30.875c16.167,7.754 34.125,11.625 53.958,11.625c19.084,0 36.754,-3.75 53.046,-11.25c16.246,-7.5 30.246,-17.542 41.913,-30.121c11.712,-12.587 20.875,-27.083 27.458,-43.462c6.625,-16.417 9.917,-33.621 9.917,-51.663c-0,-17.046 -3.167,-33.837 -9.542,-50.379"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M1849.47,1117.41l-0,138.042c-0,11.208 -1.084,22.087 -3.246,32.625c-2.171,10.541 -5.838,19.916 -11.046,28.041c-5.208,8.13 -12.083,14.75 -20.583,19.834c-8.542,5.083 -19.038,7.625 -31.459,7.625c-12.208,-0 -22.496,-2.496 -30.916,-7.459c-8.375,-4.954 -15.205,-11.5 -20.413,-19.625c-5.208,-8.125 -8.879,-17.5 -11.046,-28.037c-2.166,-10.542 -3.25,-21.546 -3.25,-33.004l0,-138.042l-52.625,0l0,138.042c0,17.541 2.084,34.458 6.292,50.754c4.212,16.246 11.004,30.579 20.417,42.875c9.416,12.371 21.67,22.246 36.791,29.746c15.125,7.5 33.5,11.25 55.125,11.25c20.834,-0 38.754,-3.542 53.75,-10.667c15,-7.125 27.292,-16.787 36.834,-29c9.5,-12.167 16.5,-26.417 20.958,-42.708c4.462,-16.246 6.667,-33.667 6.667,-52.25l-0,-138.042l-52.25,0Z"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M2165.63,1279.29c-4.079,-8.501 -9.871,-15.792 -17.375,-21.921c-7.5,-6.084 -16.621,-11.163 -27.459,-15.246c-10.791,-4.083 -22.787,-7.879 -35.995,-11.458c-10.213,-2.542 -19.171,-4.959 -26.917,-7.209c-7.754,-2.292 -14.171,-4.837 -19.25,-7.625c-5.083,-2.833 -8.963,-6.046 -11.629,-9.75c-2.663,-3.666 -3.996,-8.333 -3.996,-13.916c-0,-9.667 3.537,-17.167 10.662,-22.505c7.125,-5.329 17.921,-7.995 32.417,-7.995c8.129,-0 16.125,1 24.046,3.041c7.875,2.038 15.167,4.5 21.917,7.413c6.75,2.958 12.458,5.921 17.166,9c4.709,3.045 7.913,5.462 9.709,7.254l23.291,-42.75c-11.958,-8.125 -25.962,-15.171 -41.962,-21.167c-16.038,-5.962 -33.579,-8.962 -52.625,-8.962c-13.746,-0 -26.579,1.879 -38.542,5.546c-11.958,3.662 -22.412,9.083 -31.454,16.204c-9.042,7.129 -16.088,16.004 -21.167,26.708c-5.083,10.671 -7.625,22.879 -7.625,36.588c0,10.454 1.579,19.416 4.746,26.916c3.213,7.496 7.958,14.079 14.338,19.829c6.329,5.709 14.287,10.671 23.829,14.88c9.541,4.166 20.796,8.037 33.75,11.625c10.666,3.041 20.337,5.833 28.958,8.37c8.667,2.546 16.046,5.338 22.129,8.38c6.121,3.079 10.829,6.625 14.121,10.708c3.292,4.037 4.963,9 4.963,14.875c-0,18.542 -14.505,27.833 -43.505,27.833c-10.416,0 -20.579,-1.292 -30.5,-3.833c-9.916,-2.546 -18.916,-5.625 -27.083,-9.338c-8.121,-3.666 -15.167,-7.37 -21.167,-11.037c-5.958,-3.713 -10.083,-6.708 -12.375,-8.958l-23.25,45.375c15.75,11.166 33.542,19.833 53.38,25.916c19.829,6.125 39.916,9.167 60.25,9.167c13.208,-0 25.875,-1.458 37.954,-4.379c12.083,-2.959 22.75,-7.579 32.041,-13.954c9.25,-6.334 16.63,-14.667 22.125,-24.963c5.463,-10.287 8.167,-22.708 8.167,-37.162c0,-12.463 -2.037,-22.959 -6.083,-31.5"
      style="fill: #fff; fill-rule: nonzero"
    />
    <rect
      x="2234.26"
      y="1117.42"
      width="52.667"
      height="270.792"
      style="fill: #fff"
    />
    <path
      d="M2548.14,1117.79l0,177.338l-141.083,-177.713l-40.834,0l0,270.792l52.625,-0l0,-173.542l139.209,173.542l42.708,-0l0,-270.417l-52.625,0Z"
      style="fill: #fff; fill-rule: nonzero"
    />
    <path
      d="M2732.72,1342.04l-0,-70.541l114.041,-0l0,-42.709l-114.041,0l-0,-65.25l131.958,0l-0,-46.125l-184.583,0l-0,270.792l188,0l-0,-46.167l-135.375,0Z"
      style="fill: #fff; fill-rule: nonzero"
    />
  </svg>
</template>
