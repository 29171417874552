<template>
  <section class="section-hero">
    <div class="section-overlay-hero">
      <div class="container-hero">
        <div class="container-half">
          <div class="hero-logo">
            <a href="https://solimousine.com">
              <SoLimousineLogo />
            </a>
          </div>
          <div class="hero-intro">
            <h1>Your Journey, Our commitment to Excellence</h1>
            <p class="f-subtitle">
              Experience the pinnacle of luxury travel with our bespoke
              services. From the heart of Bucharest, journey to your destination
              in comfort and elegance, enjoying unparalleled service and premium
              vehicles.
            </p>
            <button @click="classNotSelected('/#contact')">
              Book your Ride!
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container flex flex-v container-services">
      <h1 class="h1-limit">
        Redefining Luxury Transportation with Excellence and Reliability
      </h1>
      <div class="services-list">
        <div
          class="service-item"
          v-for="service in servicesList"
          :key="service.title"
        >
          <h3>{{ service.title }}</h3>
          <div class="service-item-text">
            <div class="service-item-separator"></div>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
      <div class="services-features">
        <div
          class="service-feature-item"
          v-for="feature in servicesFeaturesList"
          :key="feature.title"
        >
          <h3>{{ feature.title }}</h3>
          <p>{{ feature.text }}</p>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container-h05">
      <p class="f-h05">
        Effortless Elegance, Exceptional Service, Exquisite Journeys
      </p>
    </div>
  </section>
  <section>
    <div class="container" id="classes">
      <h1 class="h1-limit">
        A Selection of the Finest Vehicles for Your Ultimate Comfort & Needs
      </h1>
      <div class="services-classes-list">
        <div
          class="service-class-item"
          v-for="item in servicesClasses"
          :key="item.id"
          :class="{ 'service-class-item-selected': selectedClassID == item.id }"
        >
          <div class="service-class-item-texts">
            <h3>{{ item.title }}</h3>
            <p class="f-subtitle">{{ item.subtitle }}</p>
            <p>{{ item.text }}</p>
          </div>
          <div class="service-class-item-cta">
            <button
              @click="selectClass(item.id)"
              v-if="item.id != selectedClassID"
            >
              Select Class
            </button>
            <p v-if="item.id == selectedClassID" class="f-correct">Selected</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-contact">
    <div class="container flex flex-h container-contact">
      <div class="contact-texts">
        <div
          class="contact-text"
          v-for="contact in contactTexts"
          :key="contact.title"
        >
          <h2>{{ contact.title }}</h2>
          <p>{{ contact.text }}</p>
        </div>
      </div>
      <form
        id="contact"
        class="contact-form"
        name="contactForm"
        netlify-honeypot="bot-field"
        method="POST"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contactForm" />
        <div class="input-group">
          <label for="passangerName" class="f-label">Passanger's name</label>
          <input
            type="text"
            name="passangerName"
            v-model="passangerName"
            :class="passangerNameState"
            @blur="inputBlurred('NameBlur')"
          />
          <p class="f-label l-error">{{ passangerNameError }}</p>
        </div>

        <div class="input-group">
          <label for="passangerPhone" class="f-label"
            >Passanger's phone number</label
          >
          <input
            type="phone"
            name="passangerPhone"
            v-model="passangerPhone"
            :class="passangerPhoneState"
            @blur="inputBlurred('PhoneBlur')"
          />
          <p class="f-label l-error">{{ passangerPhoneError }}</p>
        </div>
        <div class="input-group">
          <label for="passangerEmail" class="f-label">Email</label>
          <input
            type="email"
            name="passangerEmail"
            v-model="passangerEmail"
            :class="passangerEmailState"
            @blur="inputBlurred('EmailBlur')"
          />
          <p class="f-label l-error">{{ passangerEmailError }}</p>
        </div>
        <div class="input-group">
          <label for="agencyPhone" class="f-label"
            >Agency phone number (optional)</label
          >
          <input
            type="phone"
            name="agencyPhone"
            v-model="agencyPhone"
            :class="agencyPhoneState"
            @blur="inputBlurred('AgencyPhoneBlur')"
          />
          <p class="f-label l-error">{{ agencyPhoneError }}</p>
        </div>
        <div class="contact-location-group">
          <div class="input-group">
            <label for="whereFrom" class="f-label">Where from</label>
            <input
              type="text"
              name="whereFrom"
              v-model="whereFrom"
              :class="whereFromState"
              @blur="inputBlurred('WhereFromBlur')"
            />
            <p class="f-label l-error">{{ whereFromError }}</p>
          </div>

          <div class="input-group">
            <label for="whereTo" class="f-label">Where to</label>
            <input
              type="text"
              name="whereTo"
              v-model="whereTo"
              :class="whereToState"
              @blur="inputBlurred('WhereToBlur')"
            />
            <p class="f-label l-error">{{ whereToError }}</p>
          </div>
        </div>
        <VueDatePicker
          v-model="dateTravel"
          dark
          name="dateTravel"
          placeholder="Select date"
          :format="dateFormat"
          @blur="inputBlurred('DateBlur')"
        ></VueDatePicker>
        <p class="f-label l-error">{{ dateTravelError }}</p>
        <div class="selected-class-booking-group">
          <div class="selected-class-group">
            <label class="f-label">Selected Class</label>
            <p
              :class="{
                'f-incorrect': selectedClassID == 0,
                'f-correct': selectedClassID > 0,
              }"
              @click="classNotSelected('/#classes')"
            >
              {{ selectedClassName }}
            </p>
          </div>
        </div>
        <div class="hourly-booking-group">
          <div class="hourly-booking-choice-group">
            <label for="hourly-booking" class="f-label"
              >Do you require hourly booking?</label
            >
            <div class="hourly-booking-answers">
              <div class="radio-button">
                <input
                  type="radio"
                  name="hourlyBooking"
                  id="hourly-yes"
                  value="Yes"
                  v-model="hourlyBooking"
                />
                <label for="hourly-yes" class="f-label">Yes</label>
              </div>
              <div class="radio-button">
                <input
                  type="radio"
                  name="hourlyBooking"
                  id="hourly-no"
                  value="No"
                  v-model="hourlyBooking"
                />
                <label for="hourly-no" class="f-label">No</label>
              </div>
            </div>
          </div>
          <div class="input-group" v-show="hourlyBooking === 'Yes'">
            <label for="hourlyHours" class="f-label">How many hours?</label>
            <input
              type="number"
              name="hourlyHours"
              v-model="hourlyHours"
              :class="hourlyHoursState"
              @blur="inputBlurred('HoursBlur')"
            />
            <p class="f-label l-error">{{ hourlyHoursError }}</p>
          </div>
        </div>
        <input hidden type="text" name="dateTravel" />
        <input hidden type="text" name="className" />
        <div class="contact-notes-group">
          <label for="" class="f-label"
            >Would you like to leave some notes?</label
          >
          <textarea
            name="addNotes"
            cols="30"
            rows="2"
            v-model="addNotes"
            :class="addNotesState"
            @blur="inputBlurred('NotesBlur')"
          ></textarea>
          <p class="f-label l-error">{{ addNotesError }}</p>
        </div>

        <input type="hidden" name="bot-field" />
        <!-- <button @click.prevent="bookRide">Book your Ride</button> -->
        <p class="p-incorrect">{{ formError }}</p>
        <button @click.prevent="bookRide" v-if="disableButton === false">
          Book your Ride
        </button>
        <p class="p-correct" v-else>Thank you! We will respond shortly</p>
      </form>
    </div>
  </section>
</template>

<script setup>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import SoLimousineLogo from "../../public/SoLimousine_Logo.vue";
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const selectedClassID = ref(0);
const selectedClassName = ref("Please select a Class");

const dateTravel = ref(new Date());
const dateFormat = (dateTravel) => {
  // const currentDate = dateTravel.value;
  const day = dateTravel.getDate();
  const month = dateTravel.getMonth() + 1;
  const year = dateTravel.getFullYear();
  const hour = dateTravel.getHours();
  const minute = dateTravel.getMinutes().toString().padStart(2, "0");
  const dateFormatted = `${day}/${month}/${year} ${hour}:${minute}`;
  return dateFormatted;
  // return `${day}/${month}/${year} ${hour}:${minute}`;
};

const passangerName = ref("");
const agencyPhone = ref("");
const passangerPhone = ref("");
const passangerEmail = ref("");
const whereFrom = ref("");
const whereTo = ref("");
const hourlyBooking = ref("");
const hourlyHours = ref("");
const addNotes = ref("");
const canSubmit = ref(0);

const dateTravelState = ref("idle");
const passangerNameState = ref("idle");
const agencyPhoneState = ref("idle");
const passangerPhoneState = ref("idle");
const passangerEmailState = ref("idle");
const whereFromState = ref("idle");
const whereToState = ref("idle");
const hourlyBookingState = ref("idle");
const hourlyHoursState = ref("idle");
const addNotesState = ref("idle");

const dateTravelError = ref("");
const passangerNameError = ref("");
const agencyPhoneError = ref("");
const passangerPhoneError = ref("");
const passangerEmailError = ref("");
const whereFromError = ref("");
const whereToError = ref("");
const hourlyBookingError = ref("");
const hourlyHoursError = ref("");
const addNotesError = ref("");
const formError = ref("");
const disableButton = ref(false);

const dateTravelValidation = ref(0);
const passangerNameValidation = ref(0);
const agencyPhoneValidation = ref(0);
const passangerPhoneValidation = ref(0);
const passangerEmailValidation = ref(0);
const whereFromValidation = ref(0);
const whereToValidation = ref(0);
const hourlyBookingValidation = ref(0);
const hourlyHoursValidation = ref(0);
const addNotesValidation = ref(0);

const dateTravelBlur = ref(false);
const passangerNameBlur = ref(false);
const agencyPhoneBlur = ref(false);
const passangerPhoneBlur = ref(false);
const passangerEmailBlur = ref(false);
const whereFromBlur = ref(false);
const whereToBlur = ref(false);
const hourlyHoursBlur = ref(false);
const addNotesBlur = ref(false);

const inputBlurred = (input) => {
  switch (input) {
    case "NameBlur":
      passangerNameBlur.value = true;
      break;
    case "AgencyPhoneBlur":
      agencyPhoneBlur.value = true;
      break;
    case "PhoneBlur":
      passangerPhoneBlur.value = true;
      break;
    case "EmailBlur":
      passangerEmailBlur.value = true;
      break;
    case "WhereFromBlur":
      whereFromBlur.value = true;
      break;
    case "WhereToBlur":
      whereToBlur.value = true;
      break;
    case "HoursBlur":
      hourlyHoursBlur.value = true;
      break;
    case "NotesBlur":
      addNotesBlur.value = true;
      break;
    case "DateBlur":
      dateTravelBlur.value = true;
      break;
  }
};

const nameRegex = /^[A-Za-z' ]+$/;
const phoneRegex = /^\+?(\d\s?){7,}$/;
const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

hourlyBooking.value = "No";

const bookRide = (e) => {
  // e.preventDefault();
  console.log(formError.value);
  if (canSubmit.value === 6) {
    if (selectedClassName.value !== "Please select a Class") {
      async function sendForm() {
        const formName = "contactForm";
        const formData = new FormData();
        // const dateFormat = dateFormat(dateTravel.value)
        formData.append("passangerName", passangerName.value);
        formData.append("agencyPhone", agencyPhone.value);
        formData.append("passangerPhone", passangerPhone.value);
        formData.append("passangerEmail", passangerEmail.value);
        formData.append("whereFrom", whereFrom.value);
        formData.append("whereTo", whereTo.value);
        formData.append("hourlyBooking", hourlyBooking.value);
        formData.append("hourlyHours", hourlyHours.value);
        formData.append("addNotes", addNotes.value);
        formData.append("dateTravel", dateFormat(dateTravel.value));
        formData.append("className", selectedClassName.value);
        formData.append("form-name", formName);

        const response = fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams(formData).toString(),
        });
        Promise.resolve(response).then((value) => {
          if (value.ok == true) {
            disableButton.value = true;
            formError.value = "";
          } else {
            formError.value =
              "There has been an error. Please send an email with your message and mention this error. Thank you!";
          }
        });
      }
      sendForm();
    } else {
      formError.value = "Please select a service class.";
    }
  } else {
    formError.value = "Please complete the form before sending.";
  }
};

window.bookRide = bookRide;

const selectClass = (id) => {
  selectedClassID.value = id;
  selectedClassName.value = servicesClasses[id - 1].title;
};

const classNotSelected = (where) => {
  router.push(where);
};

const servicesList = [
  {
    title: "Airport Transfers",
    description:
      "Whether you need to get from Bucharest to any destination in Romania or need a ride to Bucharest Airport from any location, our airport transfer service has you covered. We ensure timely pickups and drop-offs, providing a smooth and stress-free experience. Trust our professional drivers to monitor your flight schedule and adjust pickup times accordingly, ensuring you reach your destination on time and in style.",
  },
  {
    title: "Intercity Rides",
    description:
      "Our Intercity Rides service offers luxurious and reliable transportation from Bucharest to major cities across Romania and neighboring countries, including Hungary, Bulgaria, Greece, and Turkey. Whether you’re traveling for business or leisure, our professional drivers will ensure a comfortable and safe journey. Enjoy the convenience and elegance of our premium vehicles as you travel across borders with ease.",
  },
  {
    title: "Private Tours",
    description:
      "Discover the beauty and history of Romania with our exclusive private tours. Visit iconic castles such as Peles and Bran, explore the scenic regions of Maramures and Bucovina, or immerse yourself in the cultural richness of Sibiu and Retezat. Our private tours are tailored to your interests and schedule, providing an unforgettable travel experience. Enjoy the journey in our luxurious vehicles, guided by knowledgeable drivers who will ensure a memorable and comfortable tour.",
  },
  {
    title: "Hourly Booking",
    description:
      "Whether you need a chauffeur for a few hours or the entire day, our hourly booking service offers unparalleled flexibility and convenience. Ideal for business meetings, shopping trips, or a night out in the city, our professional drivers will be at your disposal, ready to take you wherever you need to go. Enjoy the freedom to plan your day without worrying about transportation, all while riding in the comfort of our luxury vehicles.",
  },
];

const servicesFeaturesList = [
  {
    title: "Punctuality",
    text: "Always on time.",
  },
  {
    title: "Comfort",
    text: "Travel in Luxury.",
  },
  {
    title: "Availablity",
    text: "Anytime, Anywhere.",
  },
  {
    title: "Safety",
    text: "Your security is our Priority.",
  },
  {
    title: "Professional Service",
    text: "Excellence at every step.",
  },
  {
    title: "Discretion",
    text: "Confidential & Private Rides.",
  },
];

const servicesClasses = [
  {
    id: 1,
    title: "Business Class",
    subtitle: "Where Comfort Meets Efficiency",
    text: "Our Business Class service offers the perfect blend of luxury and practicality. Designed for business travelers, this service ensures you arrive at your meetings relaxed and on time.",
  },
  {
    id: 2,
    title: "Standard Class",
    subtitle: "Affordable Comfort",
    text: "For those seeking a cost-effective yet comfortable travel option, our Standard Class service is the perfect choice. Enjoy a pleasant ride in our well-maintained vehicles, suitable for everyday commuting and shorter journeys.",
  },
  {
    id: 3,
    title: "Van Service",
    subtitle: "Spacious and Comfortable for Group Travel",
    text: "Our Van service is designed for group travel, accommodating up to 5 people comfortably. Perfect for family trips, group outings, or airport transfers with extra luggage, our vans offer ample space and enhanced comfort.",
  },
];

const contactTexts = [
  {
    title: "Send us your Journey Details",
    text: "Begin your luxury travel experience by providing us with the details of your journey. Whether you need a ride to the airport, a custom trip across Romania, or a specific luxury vehicle for a special occasion, we’ve got you covered.",
  },
  {
    title: "We contact you with an offer",
    text: "Once we receive your journey details, our team will quickly review your request and prepare a customized offer tailored to your needs. We strive to provide the most competitive pricing while ensuring the highest level of service and comfort.",
  },
  {
    title: "Accept & Meet you there!",
    text: "Once you’re satisfied with our offer, we’ll take care of the rest. Our professional driver will arrive at the specified location and time, ready to provide you with a seamless and luxurious travel experience.",
  },
];

watchEffect(() => {
  canSubmit.value = 0;
  // formError.value = "";
  if ((passangerName.value != "") & (passangerNameBlur.value === true)) {
    passangerNameValidation.value = nameRegex.test(passangerName.value)
      ? true
      : false;
    if (passangerNameValidation.value === true) {
      passangerNameError.value = "";
      passangerNameState.value = "input-correct";
      canSubmit.value = canSubmit.value + 1;
    } else {
      passangerNameError.value = "Please enter only your name.";
      passangerNameState.value = "input-incorrect";
    }
  }

  if ((passangerEmail.value != "") & (passangerEmailBlur.value === true)) {
    passangerEmailValidation.value = emailRegex.test(passangerEmail.value)
      ? true
      : false;
    if (passangerEmailValidation.value === true) {
      passangerEmailError.value = "";
      passangerEmailState.value = "input-correct";
      canSubmit.value = canSubmit.value + 1;
    } else {
      passangerEmailError.value = "Please enter a correct email.";
      passangerEmailState.value = "input-incorrect";
    }
  }

  if ((passangerPhone.value != "") & (passangerPhoneBlur.value === true)) {
    passangerPhoneValidation.value = phoneRegex.test(passangerPhone.value)
      ? true
      : false;
    if (passangerPhoneValidation.value === true) {
      passangerPhoneError.value = "";
      passangerPhoneState.value = "input-correct";
      canSubmit.value = canSubmit.value + 1;
    } else {
      passangerPhoneError.value = "Please enter a correct phone number.";
      passangerPhoneState.value = "input-incorrect";
    }
  }
  if ((agencyPhone.value != "") & (agencyPhoneBlur.value === true)) {
    agencyPhoneValidation.value = phoneRegex.test(agencyPhone.value)
      ? true
      : false;
    if (agencyPhoneValidation.value === true) {
      agencyPhoneError.value = "";
      agencyPhoneState.value = "input-correct";
    } else {
      agencyPhoneError.value = "Please enter a correct phone number.";
      agencyPhoneState.value = "input-incorrect";
    }
  }

  if ((whereFrom.value != "") & (whereFromBlur.value === true)) {
    if (whereFrom.value.length > 3) {
      whereFromError.value = "";
      whereFromState.value = "input-correct";
      canSubmit.value = canSubmit.value + 1;
    } else {
      whereFromError.value = "Please enter more details.";
      whereFromState.value = "input-incorrect";
    }
  }
  if ((whereTo.value != "") & (whereToBlur.value === true)) {
    if (whereTo.value.length > 3) {
      whereToError.value = "";
      whereToState.value = "input-correct";
      canSubmit.value = canSubmit.value + 1;
    } else {
      whereToError.value = "Please enter more details.";
      whereToState.value = "input-incorrect";
    }
  }

  if (hourlyBooking.value === "Yes") {
    if (hourlyHoursBlur.value === true) {
      if (hourlyHours.value >= 1) {
        hourlyHoursError.value = "";
        hourlyHoursState.value = "input-correct";
        canSubmit.value = canSubmit.value + 1;
      } else {
        hourlyHoursError.value = "Please enter more hours.";
        hourlyHoursState.value = "input-incorrect";
      }
    }
  } else {
    canSubmit.value = canSubmit.value + 1;
  }

  if ((addNotes.value != "") & (addNotesBlur.value === true)) {
    if (addNotes.value.length > 3) {
      if (addNotes.value.length < 500) {
        addNotesError.value = "";
        addNotesState.value = "input-correct";
      } else {
        addNotesError.value = "Please enter less details.";
        addNotesState.value = "input-incorrect";
      }
    } else {
      addNotesError.value = "Please enter more details.";
      addNotesState.value = "input-incorrect";
    }
  }

  if ((dateTravel.value != "") & (dateTravelBlur.value === true)) {
    if (dateTravel.value == "") {
      dateTravelError.value = "Please select a date.";
      dateTravelError.value = "incorrect";
    }
  }
});
</script>

<style>
.section-hero {
  background-image: url(../../public/assets/4k_HeroPage.webp);
  background-size: cover;
  background-repeat: no-repeat;
  height: 78svh;
  min-height: 700px;
  width: 100%;
}
.section-overlay-hero {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgba(37, 37, 37, 0) 95%);
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-half {
  width: 50%;
}
.hero-logo {
  max-width: 128px;
  margin-bottom: 64px;
}

.hero-intro {
  display: flex;
  flex-direction: column;
}

.hero-intro > button {
  margin-top: 36px;
}

.container-services {
  justify-content: center;
  align-items: center;
}

.services-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-top: var(--s-s);
}

.service-item-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--s-xs);
}

.service-item-separator {
  border-left: 3px solid var(--c-accent);
  width: 3px;
  min-height: 84px;
}

.services-features {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--s-m);
  padding: 24px;
  border: 1px solid var(--c-white);
  border-radius: 12px;
  justify-content: stretch;
  width: fit-content;
  margin-top: var(--s-l);
}

.container-h05 {
  text-align: center;
  max-width: 962px;
}

.services-classes-list {
  display: flex;
  gap: var(--s-s);
  /* flex-wrap: wrap; */
  flex-basis: auto;
  justify-content: space-around;
  align-items: stretch;
  margin-top: 12px;
}

.service-class-item {
  flex-basis: auto;
  max-width: 256px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 2px solid transparent;
  /* align-items: space-between; */
}

.service-class-item-selected {
  border-bottom: 2px solid var(--c-correct);
}
.service-class-item-cta {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.service-class-item-cta > * {
  margin-top: 24px;
}

.service-class-item-cta > p {
  padding: var(--s-s);
  /* height: 100%; */
}

.section-contact {
  background-color: var(--c-black-special);
}

.container-contact {
  display: flex;
  flex-direction: row;
  gap: 128px;
}

.contact-texts {
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 50%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contact-location-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
}

.selected-class-group > * {
  cursor: pointer;
}

.selected-class-booking-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hourly-booking-choice-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  /* align-items: center; */
}

.hourly-booking-group {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  gap: 24px;
  justify-content: space-between;
  min-height: 54px;
}

.hourly-booking-group > .input-group > input {
  width: 40%;
}

.hourly-booking-group > .input-group {
  align-self: center;
}

.hourly-booking-answers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.hourly-booking-answers > div > input {
  margin-right: 4px;
}

.contact-notes-group > button {
  margin-top: 24px;
}

.radio-button {
  display: flex;
  flex-direction: row;
}
.anti-field {
  opacity: 0;
}

@media only screen and (max-width: 1150px) {
  .container {
    padding: var(--s-xl) 24px;
  }
  .container-hero {
    padding: 24px;
  }
  .container-h05 {
    padding: 24px;
  }

  .container-half {
    max-width: 60%;
  }
}

@media only screen and (max-width: 964px) {
  .container {
    padding: var(--s-xl) 24px;
  }
  .container-hero {
    padding: 24px;
  }
  .container-h05 {
    padding: 24px;
  }

  .container-half {
    max-width: 60%;
  }

  .container-contact {
    gap: 12px;
  }
}

@media only screen and (max-width: 790px) {
  .container-contact {
    flex-direction: column;
    gap: 64px;
  }

  .container-half {
    max-width: 80%;
    width: 100%;
  }
  .services-features {
    grid-template-columns: 1fr 1fr;
  }
  .contact-texts {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .services-list {
    grid-template-columns: 1fr;
  }

  .services-classes-list {
    flex-wrap: wrap;
    gap: var(--s-l);
  }
  .service-class-item {
    max-width: max-content;
  }

  .h1-limit {
    max-width: 100%;
  }
}

@media only screen and (max-width: 365px) {
  .services-features {
    grid-template-columns: 1fr;
  }

  .container {
    padding: 128px 8px;
  }
  .container-hero {
    padding: 8px;
  }
  .container-half {
    width: 100%;
    max-width: 100%;
  }
}
</style>

<!-- @media only screen and (max-width: 1150px) {
  .container {
    padding: var(--s-xl) 24px;
  }
  .container-hero {
    padding: 24px;
  }
  .container-h05 {
    padding: 24px;
  }

  .container-half {
    max-width: 60%;
  }
}

@media only screen and (max-width: 964px) {
  .container {
    padding: var(--s-xl) 24px;
  }
  .container-hero {
    padding: 24px;
  }
  .container-h05 {
    padding: 24px;
  }

  .container-half {
    max-width: 60%;
  }

  .container-contact {
    gap: 12px !important;
  }
}

@media only screen and (max-width: 790px) {
  .container-contact {
    flex-direction: column !important;
    gap: 64px !important;
  }

  .container-half {
    max-width: 80% !important;
    width: 100% !important;
  }
  .services-features {
    grid-template-columns: 1fr 1fr !important;
  }
}

@media only screen and (max-width: 600px) {
  .services-list {
    grid-template-columns: 1fr !important;
  }

  .services-classes-list {
    flex-wrap: wrap;
    gap: var(--s-l) !important;
  }
  .service-class-item {
    max-width: max-content !important;
  }

  .h1-limit {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 365px) {
  .services-features {
    grid-template-columns: 1fr !important;
  }

  .container {
    padding: 128px 8px !important;
  }
  .container-hero {
    padding: 8px;
  }
  .container-half {
    width: 100%;
    max-width: 100%;
  }
} -->
